import {Component, OnInit, Renderer2} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {ProductDetails} from "@/model/product/ProductDetails";
import {ImageDetails} from "@/model/asset-details/ImageDetails";
import {ConfigService} from "@/config.service";
import {tap} from "rxjs/operators";
import {LoggingService} from "@services/logging.service";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    productDetailsList: ProductDetails[] = [];
    productImageList: ImageDetails[] = [];
    endPoint: string;
    imageId: number
    productNameFilter: string | null = null;
    productName: String[] = [];
    productLocationFilter: string | null = null;
    productLocation: String[] = [];
    userTypeFilter: string | null = null;
    userTypeList: String[] = [];
    productsListData: ProductDetails[] = [];
    productsDetails: ProductDetails[] = [];
    isLocalhost = window.location.hostname === 'localhost';

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private loggingService: LoggingService,
    ) {
    }

    ngOnInit() {
        this.getAllProducts();
        this.endPoint = this.configService.getConfig().END_POINT;
    }

    getImageUrl(imageId: number) {
        return `${this.endPoint}/api/image-details/get/${imageId}`;
    }



    // Get all Product Functionality
    getAllProducts() {
        this.spinner.show();
        this.appService
            .getAllProductDetails()
            .pipe(takeUntil(this.destroy$),
                tap((products) => {
                    //Product Name Duplicate remove product name create a New Array for Filter
                    this.productName = Array.from(new Set(products.map(name => name.productName.toUpperCase()))).sort((a, b) => a.localeCompare(b));
                    // remove Duplicate location create a New Array for Filter
                    this.productLocation = Array.from(new Set(products.map(location => location.location.toUpperCase()))).sort((a, b) => a.localeCompare(b));
                    //remove duplicate user type create a new array for filter
                    this.userTypeList = Array.from(new Set(products.map(userType => userType.postedUserType))).sort((a, b) => a.localeCompare(b));
                })
            )
            .subscribe((data) => {
                    this.spinner.hide();
                    this.productDetailsList = data;
                    if (!environment.production && this.isLocalhost) {
                        console.log('Products::', data)
                        console.log("product list::", this.productDetailsList);
                    }
                    this.productsDetails = this.productDetailsList.sort((a, b) => b.productId - a.productId);
                    this.productsListData = this.productDetailsList;
                    this.endPoint = this.configService.getConfig().END_POINT;
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                });
    }

    //View Product Details show in New page function Once click the button to set ProductId in local storage
    viewProfile(productId: number) {
        localStorage.setItem("productId", String(productId));
        if (!environment.production && this.isLocalhost) {
            console.log("id::", productId)
        }
        this.router.navigate(['./main/product-detail']);
    }


    // Product Filter Functionality based Product Name, Location
    productFilter(event: any, filterType: string) {
        const value = event.target.value;
        if (value != 'All') {
            switch (filterType) {
                case 'productName' :
                    this.productNameFilter = value !== 'Select Name' ? value : null;
                    break;
                case 'location' :
                    this.productLocationFilter = value !== 'Select Product Location' ? value : null;
                    break;
                case 'userType' :
                    this.userTypeFilter = value !== 'Select User Type' ? value : null;
                    break;

                default:
                    break;
            }
            this.applyProductFilter();
        } else {
            this.productsDetails = this.productDetailsList;
        }
    }


    // Apply above the filter based Data Show functionality
    applyProductFilter() {
        const productName = this.productNameFilter ? this.productDetailsList.filter(name => name.productName.toUpperCase() === this.productNameFilter) : this.productsListData;
        const userType = this.userTypeFilter ? productName.filter(type => type.postedUserType === this.userTypeFilter) : productName
        this.productsDetails = this.productLocationFilter ? userType.filter(location => location.location.toUpperCase() === this.productLocationFilter) : userType;
    }
}
